<template>
  <div>
    <Sidebar 
      :active="active" 
      v-if="showSidebar" 
      @close="toggleSidebar" 
      @logout="logout" 
      @view="viewPrivateKey"
      @import="importKey"
      @generate="generateKey('show')"
    />
    <div id="change-pin">
        <div style="font-size:2.5rem;">
            <b-icon icon="arrow-bar-left" class="border rounded p-2" @click="toggleSidebar" style="cursor:pointer;"></b-icon>
        </div>
        <form class="change-pin-form mt-4" @submit.prevent="redeem">
            <h4 class="form-title mb-4">Redeem</h4>
            <div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
			<div class="alert alert-success" v-if="showSuccess"> {{ systemMsg }} </div>
            <div class="form-group">
                <label for="current-pin">Enter Redeem Code</label>
                <input type="text" id="current-pin" class="form-control" v-model="promoCode" maxlength="17">
            </div>
            <button class="btn btn-main mt-4" type="submit">Confirm PIN</button>
        </form>
    </div>
    <ConfirmLogout />
    <PrivateKey />
    <GeneratePrivateKey :state="generateState"/>
    <ImportPrivateKey />
  </div>
</template>

<script>
export default {
    components: {
        Sidebar:  () => import('../components/Sidebar.vue'),

        // Modals
        ConfirmLogout:  () => import('../components/modal/ConfirmLogout.vue'),
        PrivateKey:  () => import('../components/modal/privateKey/PINPrivateKey.vue'),
        ImportPrivateKey:  () => import('../components/modal/privateKey/ImportPrivateKey.vue'),
        GeneratePrivateKey:  () => import('../components/modal/privateKey/GeneratePrivateKey.vue'),
    },

    computed: {
      init() {
          return this.$store.state.init;
      },
    },

    data() {
        return {
            showSidebar: false,
            generateState: '',
            active: 'redeem',
            promoCode: '',
            showError: false,
		    showSuccess: false,
            systemMsg: "",
        }
    },

    methods: {
        toggleSidebar() {
            this.showSidebar = !this.showSidebar
            this.active = 'redeem'
        },

        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },

        viewPrivateKey() {
            this.active="privatekey";
            console.log(this.active)
            console.log('..')
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal'); 
        },

        generateKey(isShown){
            this.active="privatekey";
            this.generateState = isShown
            this.$bvModal.show('generate-key-modal');
            setTimeout(()=>{
            this.generateState = ""
            },5000);
        },

        importKey: function(){
            this.active="privatekey";
            this.$bvModal.show('import-key-modal'); 
        },

        redeem() {
            let hasError = false
            if(this.promoCode == '') {
                hasError = true
                this.showError = true
                this.systemMsg = error.response.data.msgText
            }

            if(!hasError) {
                const params = {
                    promoCode: this.promoCode
                }

                const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');
            

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data,
                    url: '/cx/redeempromocode',
                };
                this.axios(options)
                    .then((response) => {
                        this.showError = false
                        this.showSuccess = true
                        this.systemMsg = "Succesfully redeemed."

                        setTimeout(()=>{
                            this.showSuccess = false
                        },6000);
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.showError = true
                        this.systemMsg = error.response.data.msgText
                        setTimeout(()=>{
                            this.clearError()
                        },10000);
                });
            }
        },

        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
            .then((response) => {
                this.init.profile = response.data;
            })
        },

        checkSession: function() {
            const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/setup-profile";
                    },1000)
                    
                }  

                })
        },
    },

    mounted() {
      this.checkSession();
    }
}
</script>

<style scoped lang="scss">
    #change-pin {
        color:white;
        background-color:black; 
        padding:40px;
        height:100vh;

        .change-pin-form {
            width:500px;

            .form-title {
                font-family:OpenSans-SB;
                color:#c08000;
            }

            .form-control {
                background-color:#231f20;
                border:none;
                color:white;
            }
        }
    }
</style>

